import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {autocomplete} from '../services/SearchService';

export default class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {options: []};
  }

  autocompleteLookup(text) {
    const results = autocomplete(text);
    this.setState({options: results});
    if (results.length === 1) {
      this.props.update(results[0]);
    } else if (results.includes(text.toUpperCase())) {
      this.props.update(text.toUpperCase());
    }
  }

  render() {

    return (
      <Autocomplete
        options={this.state.options}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        onInputChange={(ev, newVal) => this.autocompleteLookup(newVal)}
      />
    );
  }
}
