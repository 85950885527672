import owners from '../owners.json';



function getIndex(text) {
  return text.replace(/[^A-Z]/gi, '').toUpperCase().substr(0, 2);
}

export async function getPropertiesOfOwner(ownerName) {
  let prefix = getIndex(ownerName);
  if (prefix.length !== 2) {
    prefix = 'ZZ';
  }
  const unfilteredProperties = await fetch(`/data/${prefix}.geojson`).then(response => response.json());
  const filteredProperties = unfilteredProperties.features.filter(
    property => property.properties.owner_1.toUpperCase().trim() === ownerName.toUpperCase() ||
                (property.properties.owner_2 &&
                 property.properties.owner_2.toUpperCase().trim() === ownerName.toUpperCase())
  );
  return {
    type: "FeatureCollection",
    features: filteredProperties
  };
}

export function autocomplete (text) {
  const matches = owners[getIndex(text)].filter(owner => owner.toUpperCase().indexOf(text.toUpperCase()) > -1);
  return matches.splice(0,100);
}
