import React from 'react';
import {getPropertiesOfOwner} from '../services/SearchService';

function PropertyItem(props) {
  const property = props.property.properties; // ugly!
  const url = `https://property.phila.gov/?p=${property.parcel_number}`
  return (
    <div>
      <h2><a href={url}>{property.location}</a></h2>
      <h3>{property.building_code_description}</h3>
      <h3>{property.category_code_description}</h3>
      <div>Owner: { property.owner_1 }</div>
      {property.owner_2 ? <div>Owner 2: {property.owner_2}</div> : <div></div>}
      <div>Market Value: {property.market_value}</div>
      <div>Sale Date: {property.sale_date} / Sale Price: {property.sale_price}</div>
    </div>
  );
}


export default class PropertyList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {loading: true, properties: {features: []}};
  }

  async componentDidMount() {
    this.setState({loading: true, properties: {features: []}});
    const properties = await getPropertiesOfOwner(this.props.name);
    this.setState({loading: false, properties: properties});
  }

  render() {

    return (
      <div>
        {this.state.loading ? <h1>Loading!</h1>
         : this.state.properties.features.map(property => <PropertyItem key={property.properties.objectid} property={property} />)}
      </div>
    );
  }
}
