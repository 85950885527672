import React from 'react';
import './App.css';
import SearchBar from './components/SearchBar';
import PropertyList from './components/PropertyList';

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {name: ""};
  }

  render() {
    return (
      <div className="App">
        <h2>philly opa owner name search with autocomplete</h2>
        <div>
          <SearchBar update={(newName) => this.setState({name: newName})} />
        </div>
        <div>
          <PropertyList key={this.state.name} name={this.state.name} />
        </div>
      </div>
    );
  }
}
